@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #1f2937;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  z-index: 100;
}

.watermark {
  position: fixed;
  bottom: -70px;
  right: -70px;
  opacity: 0.05;
  height: 250px;
  cursor: pointer;
}

.love {
  color: black;
  z-index: 10;
}

footer a {
  color: black;
}

.rotating {
  -webkit-animation: rotating 60s linear infinite;
  animation: rotating 60s linear infinite;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 950px) {
  .main-container {
    flex-direction: column;
    margin-top: 80px;
  }
}
